<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <v-row align="center">
                <v-col cols="12" md="7" order="2" order-md="1" class="mt-20px mt-md-0">
                    <v-card>
                        <v-img src="/images/sub/vr/vr-img.jpg" max-width="730" :aspect-ratio="730 / 920" class="w-100" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="5" order="1" order-md="2">
                    <div class="pl-md-20px pl-lg-40px">
                        <div class="tit-wrap">
                            <h2 class="tit line-height-14">
                                조이포라이프 <br />
                                판교시그니쳐 쇼룸을 <br />
                                VR로 체험해보세요
                            </h2>
                        </div>
                        <div>
                            <h3 class="tit tit--xs primary--text">
                                EXPERIENCE <icon-arrow-top-right class="ml-8px ml-md-16px"/>
                            </h3>
                            <p class="txt txt--sm txt--dark mt-4px mt-md-8px">
                                언제 어디서나 3D VR스토어에서 제품 정보부터 상담까지 편하게 확인하세요.
                            </p>
                        </div>
                        <div class="mt-20px mt-md-40px">
                            <v-row>
                                <v-col cols="12" xl="auto">
                                    <v-btn v-bind="{ ...btn_primary, ...$attrs, }" x-large class="min-w-100 min-w-xl-200px font-weight-bold">
                                        <v-row align="center" justify="center" class="row--sm">
                                            <v-col cols="auto">1층</v-col>
                                            <v-col cols="auto"><v-divider vertical class="white" style="height: 14px;"/></v-col>
                                            <v-col cols="auto">욕실</v-col>
                                            <v-col cols="auto"><icon-arrow-rounded direction="right" class="d-block"/></v-col>
                                        </v-row>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" xl="auto">
                                    <v-btn v-bind="{ ...btn_primary, ...$attrs, }" x-large class="min-w-100 min-w-xl-200px font-weight-bold">
                                        <v-row align="center" justify="center" class="row--sm">
                                            <v-col cols="auto">2층</v-col>
                                            <v-col cols="auto"><v-divider vertical class="white" style="height: 14px;"/></v-col>
                                            <v-col cols="auto">욕실</v-col>
                                            <v-col cols="auto"><icon-arrow-rounded direction="right" class="d-block"/></v-col>
                                        </v-row>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import IconArrowRounded from "@/components/client/icons/icon-arrow-rounded.vue";
import IconArrowTopRight from "@/components/client/icons/icon-arrow-top-right.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        IconArrowRounded,
        IconArrowTopRight,
    },
    data() {
        return {
            btn_primary,
        }
    } 
};
</script>

<style lang="scss" scoped>

</style>
