<template>
    <client-page>
        <page-section class="page-section--first pb-0">            
            <page-section class="page-section--xs py-0">
                <v-card>
                    <v-img cover src="/images/sub/interir/interir-img.jpg" :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1330 / 272 : 300 / 300">
                        <div class="d-flex align-center h-100 pa-20px py-md-30px px-lg-60px">
                            <div class="w-100 white--text text-center">
                                <v-row align="center" class="row--xs">
                                    <v-col>
                                        <v-divider class="grey lighten-5 mw-130px ml-auto" />
                                    </v-col>
                                    <v-col cols="auto">
                                        <p class="txt txt--sm white--text">홈 스타일링 큐레이팅 서비스</p>
                                    </v-col>
                                    <v-col>
                                        <v-divider class="grey lighten-5 mw-130px" />
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-4px">더 이상 상상만 하지 마세요.</h3>
                                <p class="txt txt--sm white--text mt-10px mt-md-20px">
                                    인스타에서 봤던 예쁜 집, 우리집이 될 순 없을까? 더 이상 고민만, 상상만 하지 마세요. <br>
                                    내 취향과 스타일만 알려주시면 나머지는 저희가 알려드리겠습니다. 고민중이시라면 지금 바로 시작해 보세요.
                                </p>
                            </div>
                        </div>
                    </v-img>
                </v-card>
            </page-section>        
            <page-section class="page-section--xs pb-0">
                <v-row>
                    <v-col v-for="(item, index) in process" :key="index" cols="12" sm="4">     
                        <v-card>                  
                            <v-img cover :src="item.image" :aspect-ratio="1 / 1">
                                <div class="d-flex align-center h-100 pa-20px py-md-30px px-lg-60px">
                                    <div class="w-100 white--text text-center">
                                        <p class="txt txt--sm white--text mt-10px mt-md-20px">
                                            STEP {{index+1}}
                                        </p>
                                        <h3 class="tit tit--xs mt-4px">{{item.title}}</h3>
                                    </div>
                                </div>
                            </v-img>
                        </v-card>
                        <div class="pt-12px pt-md-16px">
                            <h3 v-html="item.subTitle" class="tit tit--xs"></h3>
                            <p v-html="item.txt" class="txt txt--sm txt--dark mt-4px mt-md-8px"></p>
                        </div>
                    </v-col>
                </v-row>
            </page-section>
        </page-section>     
        <page-section class="page-section pb-0">
            <v-row align="center">
                <v-col cols="12" md="7" lg="8">     
                    <v-card>                  
                        <v-img cover src="/images/sub/interir/interir-img4.jpg" :aspect-ratio="880 / 400">
                            <div class="d-flex flex-column justify-space-between h-100 pa-20px pa-md-30px pa-lg-40px">
                                <h3 class="white--text tit tit--xs">3D 인테리어</h3>
                                <div class="w-100">
                                    <v-img src="/images/logo-light.svg" :max-width="$vuetify.breakpoint.mdAndUp ? 220 : 160" :aspect-ratio="220 / 24" contain class="ml-auto" style="opacity: .4;" />
                                </div>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="5" lg="4">
                    <div class="pl-md-20px pl-lg-40px">
                        <h3 class="tit tit--xs">서비스 준비중입니다.</h3>
                        <p class="txt txt--sm txt--dark mt-4px mt-md-8px">
                            나만의 감각으로 내 취향대로. 내 상상이 현실이 되는 공간. <br>
                            3D 메타버스 기술로 상상하던 공간을 눈으로 직접 확인하세요. <br>
                            AI와 컨설팅 전문가가 추천해 주는 온라인 스타일링
                            서비스입니다.
                        </p> 
                        <div class="mt-20px mt-md-40px">
                            <v-btn v-bind="{ ...btn_primary, ...$attrs, }" x-large class="min-w-md-200px">3D 인테리어 시작하기</v-btn>
                        </div> 
                    </div>
                </v-col>
            </v-row>
        </page-section> 
        <page-section class="page-section page-section--last">
            <v-row>

                <!-- S::quiz -->
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">스타일 퀴즈</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    마음에 드는 <br>
                                    <span class="primary--text">스타일 이미지</span>를 <br>
                                    선택해주세요
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <!-- E::quiz -->

                
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">스타일 퀴즈</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    마음에 드는 <br>
                                    <span class="primary--text">수전 이미지</span>를 <br>
                                    선택해주세요
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">스타일 퀴즈</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    마음에 드는 <br>
                                    <span class="primary--text">양변기 이미지</span>를 <br>
                                    선택해주세요
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">스타일 퀴즈</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    마음에 드는 <br>
                                    <span class="primary--text">컬러와 색감</span>을 <br>
                                    선택해주세요
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">스타일 퀴즈</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    마음에 드는 <br>
                                    <span class="primary--text">타일 재질</span> <br>
                                    선택해주세요
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">범위설정</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    공간을 <br>
                                    <span class="primary--text">디자인하는 이유</span>를 <br>
                                    알려주세요
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">범위설정</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    원하는 <span class="primary--text">디자인 범위</span>를 <br>
                                    알려주세요
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">범위설정</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    선호하는 <br>
                                    <span class="primary--text">인테리어 작업방식</span>을 <br>
                                    선택해주세요.
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col cols="6" md="4">     
                    <v-card class="grey lighten-5 h-100">                  
                        <v-img cover src="" :aspect-ratio="1 / 1">
                            <div class="pa-20px pa-md-30px pa-lg-40px">
                                <v-row align="center" class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="material-icons-outlined font-size-16 d-block">favorite_border</span>
                                    </v-col>
                                    <v-col>
                                        <p class="txt txt--sm txt--dark">범위설정</p>
                                    </v-col>
                                </v-row>
                                <h3 class="tit tit--xs mt-10px mt-md-20px">
                                    분석결과 <br>
                                    <span class="primary--text">모던 클래식 스타일</span>을 <br>
                                    추천해드려요
                                </h3>
                            </div>
                        </v-img>
                    </v-card>
                </v-col>

            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        PageSection,
    },
    data() {
        return {
            btn_primary,
            process : [
                {
                    title : "YOUR SPACE",
                    subTitle : "당신의 취향과 공간을 보여주세요.",
                    txt : "간단한 설문을 통해 공간의 면적, 구성원, 예산에 맞는 스타일 등을 파악합니다. 올려주신 공간 이미지는 3D 도면으로 변환해 컨설팅에 활용합니다.",
                    image : "/images/sub/interir/interir-img2.jpg",
                },
                {
                    title : "CURATING PLAN",
                    subTitle : "1:1 인테리어 디자인 컨설팅이 시작됩니다.",
                    txt : "조이포라이프의 홈스타일링 서비스는 AI 와 디자이너의 1:1 맞춤형 디자인으로 이루어집니다. 온라인 채널을 통해 만족도 높은 서비스를 경험하실 수 있습니다.",
                    image : "/images/sub/interir/interir-img3.jpg",
                },
                {
                    title : "BUY A PRODUCT",
                    subTitle : "3D디자인 된 내 공간을 보고 <br class='d-none d-xl-block' />제품을 쇼핑하세요.",
                    txt : "내 취향이 담긴 공간을 고해상도 3D 렌더링 결과물을 드립니다. <br />디자인에 포함된 가구와 소품들의 구매링크를 통해 쉽고 빠르게 집을 바꿔보실 수 있습니다.",
                    image : "/images/sub/interir/interir-img4.jpg",
                },
            ]
        }
    } 
};
</script>

<style lang="scss" scoped>
</style>
