var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mt-20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "7",
      "order": "2",
      "order-md": "1"
    }
  }, [_c('v-card', [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/vr/vr-img.jpg",
      "max-width": "730",
      "aspect-ratio": 730 / 920
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "pl-md-20px pl-lg-40px"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit line-height-14"
  }, [_vm._v(" 조이포라이프 "), _c('br'), _vm._v(" 판교시그니쳐 쇼룸을 "), _c('br'), _vm._v(" VR로 체험해보세요 ")])]), _c('div', [_c('h3', {
    staticClass: "tit tit--xs primary--text"
  }, [_vm._v(" EXPERIENCE "), _c('icon-arrow-top-right', {
    staticClass: "ml-8px ml-md-16px"
  })], 1), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-4px mt-md-8px"
  }, [_vm._v(" 언제 어디서나 3D VR스토어에서 제품 정보부터 상담까지 편하게 확인하세요. ")])]), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-100 min-w-xl-200px font-weight-bold",
    attrs: {
      "x-large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("1층")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "white",
    staticStyle: {
      "height": "14px"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("욕실")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    staticClass: "d-block",
    attrs: {
      "direction": "right"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-100 min-w-xl-200px font-weight-bold",
    attrs: {
      "x-large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("2층")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "white",
    staticStyle: {
      "height": "14px"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("욕실")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('icon-arrow-rounded', {
    staticClass: "d-block",
    attrs: {
      "direction": "right"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }